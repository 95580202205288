
	frappe.templates['shipment_service_selector_fedex'] = `{% if (data.output.rateReplyDetails.length) { %}
<div style="overflow-x:scroll;">
    <h5>Shipment Services</h5>
    <table class="table table-bordered table-hover">
        <thead class="grid-heading-row">
            <th style="padding-left: 12px;">Service Name</th>
            <th style="padding-left: 12px;">Price</th>
            <th style="padding-left: 12px;"><!-- SELECT SERVICE --></th>
        </thead>
        <tbody>
            {% for (var i = 0; i < data.output.rateReplyDetails.length; i++) { %}
                {% if(data.output.rateReplyDetails[i].ratedShipmentDetails[0].totalNetCharge > 0){ %}
                <tr id="data-product-{{i}}">
                    <td class="service-info">{{data.output.rateReplyDetails[i].serviceName}}</td>
                    <td class="service-info" nowrap>{{format_currency(data.output.rateReplyDetails[i].ratedShipmentDetails[0].totalNetCharge, data.output.rateReplyDetails[i].ratedShipmentDetails[0].currency, 2) }}</td></td>
                    <td style="width:10%;vertical-align: middle;">
                        <button
                            data-type="product_service"
                            id="data-product-{{i}}" type="button" class="btn btn-success">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-square" viewBox="0 0 16 16">
                                <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z"/>
                                <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
                              </svg>
                        </button>
                    </td>
                </tr>
                {% } %}
			{% } %}
        </tbody>
    </table>
</div>
{% } else { %}
	<div style="text-align: center; padding: 10px;">
		<span class="text-muted">
			No Services Available
		</span>
	</div>
{% } %}

<style type="text/css" media="screen">
    .modal-dialog {
        width: 900px;
    }
    .service-info {
        vertical-align: middle !important;
        padding-left: 12px !important;
    }

    .ship {
        font-size: 16px;
    }
</style>`;
